import React from "react";
import HeaderWebsite from "./headerWebsite";
import Footer from "./components/Footer";

const Contact = () => {
  return (
    <>
      <HeaderWebsite />
      <div className="container">
        <div className="privacy-card">
          <center>
            <h3>Contact Us</h3>
          </center>
        </div>
        <div id="content-privacy" className="privacy-card">
          {/* <center> */}
          {/* <p>Travel planning at your fingertips</p> */}
          <h4 className="text-muted">Call Us</h4>
          <br></br>
          <h5 className="text-muted">Live Customer Support</h5>
          <a href="tel:+1(855) 478-6093" className="">
            <p style={{ color: "green" }}> +1(855) 478-6093</p>
          </a>
          <h4 className="text-muted">Send us an email</h4>
          <br></br>
          <h5
            className="text-muted"
            style={{
              textTransform: "none",
            }}
          >
            Mail us at
          </h5>
          {/* <p>12345689</p> */}
          <a href="mailto:info@flynowithus.com">Info@flynowithus.com</a>
          <br></br> <br></br>
          <h5 className="text-muted">For billing related inquiries email</h5>
          {/* <p>12345689</p> */}
          <a href="mailto:billing@flynowithus.com">Billing@flynowithus.com</a>
          <br></br>
          <br></br>
          {/* </cente  <br></br>r> */}
        </div>
      </div>
      <br></br>

      <br></br>
      <Footer />
    </>
  );
};

export default Contact;
