import React from "react";
import HeaderWebsite from "./headerWebsite";
import Footer from "./components/Footer";

const CookiePolicy = () => {
  return (
    <>
      <HeaderWebsite />
      <div className="container">
        <div className="privacy-card">
          <center>
            <h3>Cookie Policy</h3>
          </center>
        </div>
        <div id="content-privacy" className="privacy-card">
          {/* <center> */}

          {/* <h3>Cookie Policy</h3> */}
          <br></br>

          <p>
            This Cookie Policy explains when and why cookies are used by
            Flynowithus.com and its subsidiary and its affiliates (collectively
            referred to as “Flynowithus”, “we”, “us” or “our”), on our website
            <a className="ml-2" href="https://flynowithus.com" target="_blank">
              www.flynowithus.com
            </a>{" "}
            ; (“Website”) and Mobile Application (“Booking Platform”) and on
            certain partner and licensee websites that we do not own or operate
            but with whom we have agreements (together, the “Service”).
          </p>

          <p>
            <ol>
              <li className="privacy-head">What are Cookies?</li>
              <br></br>
              <p>
                <ul>
                  <li>
                    {" "}
                    Cookies are small bits of text that are downloaded to the
                    devices you use to reach and use the Service. Your browser
                    or device holds these cookies and makes them available every
                    time you visit a site again so that it can recognize you and
                    remember important information about you.
                  </li>
                  <br></br>
                  <li>
                    Session Cookies are temporary cookies that remain in your
                    browser or device until you leave the site. They are then
                    deleted from your device once you leave the Service.
                  </li>
                  <br></br>
                  <li>
                    Persistent Cookies remain in your browser or device for much
                    longer – they remain there until you delete them or they
                    reach their expiry date, depending on the cookie. These may,
                    for example, be used to remember your travel preferences
                    when you use the Service, recognize you on your return, and
                    enhance your experience with the Service. Use of a
                    persistent cookie may be linked to personal information
                    while interacting with the Service, such as when you choose
                    to store your user name and address, your contact number(s),
                    and e-mail IDs, so that you do not have to enter them each
                    time you access your accounts. Then, a persistent cookie
                    will be stored on your computer, which is linked to your
                    account information. If you reject the persistent cookie,
                    you may still use the Service but you will be limited in
                    some areas of it.
                  </li>
                  <br></br>
                </ul>
              </p>

              <li className="privacy-head">
                {" "}
                Can I Change My Cookie Preferences?
              </li>
              <br></br>
              <p>
                <ul>
                  <li>
                    {" "}
                    Cookies are small bits of text that are downloaded to the
                    devices you use to reach and use the Service. Your browser
                    or device holds these cookies and makes them available every
                    time you visit a site again so that it can recognize you and
                    remember important information about you.
                  </li>
                  <br></br>
                  <li>
                    Session Cookies are temporary cookies that remain in your
                    browser or device until you leave the site. They are then
                    deleted from your device once you leave the Service.
                  </li>
                  <br></br>
                  <li>
                    Persistent Cookies remain in your browser or device for much
                    longer – they remain there until you delete them or they
                    reach their expiry date, depending on the cookie. These may,
                    for example, be used to remember your travel preferences
                    when you use the Service, recognize you on your return, and
                    enhance your experience with the Service. Use of a
                    persistent cookie may be linked to personal information
                    while interacting with the Service, such as when you choose
                    to store your user name and address, your contact number(s),
                    and e-mail IDs, so that you do not have to enter them each
                    time you access your accounts. Then, a persistent cookie
                    will be stored on your computer, which is linked to your
                    account information. If you reject the persistent cookie,
                    you may still use the Service but you will be limited in
                    some areas of it.
                  </li>
                  <br></br>
                </ul>
              </p>

              <li className="privacy-head"> Why do we use cookies?</li>
              <br></br>
              <p>
                Cookies allow us to improve the website's content,
                functionality, speed and usability. Their tracking ability also
                helps us understand your preferences, so you do not need to
                provide the same information every time you visit or multiple
                times during a visit.
              </p>

              <li className="privacy-head"> How do we use cookies?</li>
              <br></br>
              <p>
                This website uses different kinds of cookies:
                <br></br>
                <br></br>
                <ul>
                  <li>
                    {" "}
                    Technical Cookies: They are essential for the website to
                    work properly.
                  </li>
                  <br></br>
                  <li>
                    Functional Cookies: They are needed to provide the services
                    requested by users on the website.
                  </li>
                  <br></br>
                  <li>
                    Analytical Cookies: They allow us to see how visitors use
                    the website and to record which content they view and are
                    interested in. This helps us to improve the service we offer
                    by making sure our users are finding what they are looking
                    for.
                  </li>
                  <br></br>

                  <li>
                    Social Cookies: They allow us to provide functionalities
                    relating to social networks (Facebook, Google and Twitter).
                  </li>
                  <br></br>
                  <li>
                    Affiliated Cookies: They allow us to track visits from our
                    affiliated websites.
                  </li>
                  <br></br>
                  <li>
                    Advertising Cookies: They collect information about your
                    preferences and choices on the site. They target advertising
                    networks, which use them to show you customized advertising
                    on other websites.
                  </li>
                </ul>
              </p>

              <li className="privacy-head"> How To Control Cookies?</li>
              <br></br>
              <p>
                You can control and/or delete cookies anytime you choose. For
                details, see{" "}
                <a href="https://aboutcookies.org" target="_blank">
                  www.aboutcookies.org
                </a>
                . Cookie preferences need to be set for each browser (Internet
                Explorer, Google Chrome, Mozilla Firefox or Safari). You usually
                have several ways of managing cookies, such as enabling or
                disabling cookies entirely, deleting some cookies that are
                already stored within the browser, blocking certain websites
                from storing any cookies on your computer, or blocking
                third-party cookies (cookies stored on your computer by another
                website). If you do this, some parts of the website may not
                work.
              </p>

              <br></br>
              <p>
                To learn more about cookies, please visit
                <a
                  className="ml-2"
                  target="_blank"
                  href="https://aboutcookies.org"
                >
                  www.aboutcookies.org
                </a>
              </p>
            </ol>
          </p>
          <br></br>
        </div>
      </div>

      <br></br>
      <br></br>
      <Footer />
    </>
  );
};

export default CookiePolicy;
