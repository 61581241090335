import React from "react";
import HeaderWebsite from "./headerWebsite";
import Footer from "./components/Footer";

const Terms = () => {
  return (
    <>
      <HeaderWebsite />
      <div className="container">
        <div className="privacy-card">
          <center>
            <h3>Terms and Conditions</h3>
          </center>
        </div>
        <div id="content-privacy" className="privacy-card">
          {/* <center> */}

          {/* <h3>Terms and Conditions</h3> */}
          <br></br>
          <p>
            Flynowithus is a leading travel portal for flight search and online
            flight booking. We suggest all our website users, read the terms and
            conditions in detail before using any of the services including
            flight booking. You agree to comply with these terms and conditions
            by accessing and using our website for any service or information.
            We advise you not to continue on our website in case you do not
            acknowledge all the terms and conditions without any discrepancy.
            You are conceding the fact that HolidayBreakz.com also has the right
            to modify or change these terms anytime and for whatever reasons
            without giving any notice or warning. You are subjected to terms and
            conditions in force at the time you are using the website or using
            any service or purchasing. You must also read the terms and
            conditions along with the privacy policy regarding any individual
            airline before booking flight tickets. We have noted all the terms
            and conditions for using the website as below, we recommend you read
            these terms with the utmost attention.
          </p>

          <br></br>
          <p>
            The timeframe for refund against any cancellation done within the 24
            hours of booking bracket is 48-72 hours. You must contact your bank
            and receive an authorization code to get an urgent refund
          </p>
          <p>
            If you have initiated the flight cancellation directly with the
            airline, you have to inform Flynowithus regarding it. We refund you
            an amount equal to the base fare after deducting the service fee and
            taxes.
          </p>
          <p>
            There is no guarantee about the airfares till the completion of the
            flight booking process. The prices are subject to change without any
            prior notice.
          </p>

          <p>
            In any circumstances or due to technical error, the fare at which
            booking is done is not available anymore, Flynowithus will give you
            substitute options. It is completely your prerogative to accept the
            new option at the presently available option or cancel your booking
            without any extra charge.
          </p>

          <p>
            Once the booking is done and the payment process is completed, the
            flight ticket will be emailed to you within 4 hours of full payment
            realization.
          </p>
          <p>
            We will inform you through email or phone in case of any payment
            declaration due to insufficient funds or any credit card issues. The
            customer completely must talk with the bank or credit card company
            and resolve the problem for the clearance of the ticket.
          </p>
          <p>
            Extra charges may be levied due to the usage of multiple cards for
            flight booking.
          </p>

          <p>
            The customers are not permitted to make any name changes after the
            booking is done. You must report to Flynowithus for any error in the
            booking or tickets on the same day of ticket issuance.
          </p>
          <p>
            There is a penalty or fine imposed by the airlines in case of flight
            cancellation or changes. At the same time, even some nonrefundable
            tickets can be reused as per the guidelines of the airline. The
            entire ticket amount in case of no-show or partially used tickets is
            charged by the airlines and there is no refund
          </p>
          <p>
            There is a variation in refund process time between airlines.
            Normally it takes 10-12 weeks for the refund amount to be shown in
            the customer's bank account.
          </p>
          <p>
            The refund amount is only processed towards the original form of
            payment.
          </p>

          <p>
            The normal validity period of the flight ticket is one year from the
            issuance date. However, due to various airfare restrictions, the
            validity may not be possible in some scenarios. You shall talk with
            the airlines directly to know more regarding this.
          </p>
          <p>
            The term Domestic means any flight within the fifty states of the
            USA and International means all the flights apart from domestic. The
            advisable check-in time is 2 hours for domestic flights and 3 hours
            for international prior to departure time. You must confirm the seat
            or other special services directly with airlines not less than 3
            days before departure.
          </p>

          <p>
            Infant fare is applicable for the traveler who is less than 24
            months of age at the time of travel or child fare will be applied.
          </p>
          <p>
            You are advised to contact our support center before making any
            reservation for unaccompanied minors as the unaccompanied policy
            varies between airlines.
          </p>
          <p>
            If the flight is run by a codeshare partner, you must make sure that
            you appear at the right airline counter.
          </p>

          <p>
            It is solely your responsibility to ensure that all the details
            furnished on your travel documents are correct and without error. In
            case of any inconsistency, you should bring it to our notice
            instantaneously.
          </p>

          <p>
            Flynowithus does not provide visas for any country. Please arrange
            your visa before the departure date as per your travel itinerary.
            You can get more details about visas from the airlines or visa
            consulate of the country.
          </p>

          <p>
            Flynowithus is only a flight booking platform and does not take any
            liability for flight cancelation, rescheduling, or delays by the
            airline for different reasons. For customers who have used our
            platform for booking, we will try to help you with new arrangements
            until you have checked in with the airline. You shall work directly
            with the airline for any support after check-in. There may be
            charges implied for any modification or changes in the flight
            ticket, which you need to pay directly to the airline at the time of
            making the request.
          </p>
          <br></br>

          <br></br>
          <h3>Cancel and Exchange</h3>
          <br></br>
          <p>
            we offer mostly non-refundable flight tickets on our website with
            different airlines. However, some airlines offer refundable tickets
            and you get refunds against any cancelation. You can use the credit
            amount for future travel with the same airline within the valid
            date. For additional information, you must contact the specific
            airline.
          </p>
          <p>
            Flight tickets for which cancellation is allowed; you must place the
            cancellation request with our customer service center before the
            flight departure. However, we do not provide any guarantee of
            booking cancellation and refund.
          </p>
          <p>
            If you want to book tickets by redeeming your airline credit, you
            shall pay the fare differences if any, penalty and booking fee by
            Flynowithus. These are governed by the airfare rules and policies of
            the respective airline and we do not have any control over them.
          </p>

          <p>
            Flynowithus is a flight-booking platform and we do not offer any
            other representations for the fee we charge. Our agents provide
            support and guidance for searching and booking flights on our
            website to the customers based on availability etc.
          </p>
          <br></br>
          <h3>Cancel and Refund</h3>
          <br></br>
          <p>
            You must place a flight cancelation request within 24 hours of
            booking to get a refund in most cases. If you have not taken the
            flight and informed our agents about the flight cancellation, you
            can get a trip protection refund within 10 days of purchase. You can
            process your cancellation request to our agents only through the
            phone. We like to acknowledge the cancellation and initiate a refund
            plea if you meet the subsequent conditions
          </p>
          <p></p>
          <p>
            The airline permits the cancellation and refund request put through
            by you with that specific airline.
          </p>
          <p>
            'No show' is marked against your reservation and a refund is
            permitted by the airline against 'No Show' cases
          </p>
          <p>
            If the cases wherever we have protected waivers from the airline to
            begin your request for cancellation and refund
          </p>

          <p>
            We do not guarantee any timeline for clearing your cancellation and
            refund application and all requests are handled serial-wise. We will
            send an email accepting your cancellation appeal after you place
            your request with our support executive with a reference for future
            correspondence. By no means does this authorize you to get the
            refund automatically. We will then take your request to the
            concerned airline to start the refund process as per the rules and
            regulations of the supplier.
          </p>
          <p>
            It should be noted that the service fee is non-refundable which we
            take at the time of original flight booking.
          </p>
          <p>
            It is completely the prerogative of travel suppliers to process the
            refund and it may take 60-90 days to finish the process from the
            date of cancellation request. Once the refund process is completed,
            it may take some more time to show the fund on your bank statement.
          </p>
          <p>
            The supplier or airline may levy a penalty against your refund
            request. Apart from it, we may also take a service fee as
            applicable. The fee or charges will be as per ticket booked and per
            passenger.
          </p>
          <p>
            We shall reimburse the service fee if the travel supplier does not
            accept the refund request and process it further. However, the
            booking fee at the time of flight booking is non-refundable.
          </p>
          <br></br>
          <h3>Flight changes that are already booked</h3>
          <br></br>
          <p>
            Any changes done to the existing booking or travel itinerary are
            regulated by the airfare rules of that specific airline. After the
            flight booking is done, most airlines do not permit any changes in
            the name of passengers or dates of travel. We do not provide any
            undertaking or are not responsible anyway for flight bookings where
            the initial booking details are changed by the supplier on the
            appeal of the passenger or due to scheduled changes from the
            supplier end only.
          </p>
          <br></br>

          <h3>Newsletter Terms and Conditions</h3>
          <br></br>
          <p>
            By subscribing to our newsletter, you agree to receive emails from
            Flynowithus best flight deals, discounts and travel tips and more
            via email.
          </p>
          <br></br>
          <h3>Discount Terms</h3>
          <br></br>
          <p>
            Discounts offered may vary based on factors including to the
            following:
          </p>

          <br></br>
          <ul>
            <li>Airlines utilized</li>

            <li>Class of service</li>
            <li>Destination</li>
            <li>Time of year (low, mid, or high season)</li>
            <li>Advance booking notice</li>
            <li>Minimum stay requirements</li>
            <li>Flight load</li>
          </ul>

          <br></br>
          <br></br>
          <p>
            Flynowithus reserves the right to modify or terminate discounts
            without prior notice. Discounts are subject to availability and may
            not be combined with other offers. Flynowithus is not responsible
            for any errors or omissions. Travel dates and conditions apply.
          </p>
          <br></br>
          <br></br>
          <p>For more information, please contact our customer service team.</p>
        </div>
      </div>

      <br></br>
      <br></br>
      <Footer />
    </>
  );
};

export default Terms;
