import React from "react";
import HeaderWebsite from "./headerWebsite";
import Footer from "./components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderWebsite />
      <div className="container">
        <div className="privacy-card">
          <center>
            <h3>Privacy Policy</h3>
          </center>
        </div>
        <div id="content-privacy" className="privacy-card">
          {/* <center> */}
          <p>
            Our topmost importance is protecting the personal information of our
            customers. We recommend you to read our privacy policy before using
            our website.
          </p>

          <p>
            You concede to use your information in agreement with our policy
            using the Flynowithus website. Therefore our customers must read the
            privacy policy in detail and contact us for any questions.
          </p>
          <p>
            The privacy policies are subject to change contingent on new policy
            guidelines from the Government and Airlines; therefore customers are
            advised to check this page regularly for updated policies.
          </p>

          <p>
            We mainly collect non-personal attributes such as anonymous User IDs
            or device IDs from our visitors on this website. Hence we are not
            empowered to identify you as an individual in the real world.
          </p>
          <p>
            Sometimes based on necessity, we may collect some personal
            information like your name, email address, and passport number to
            provide the best services. However, we ensure you do not
            intentionally collect any unwarranted personal information from us
            to impact your privacy as little as possible.
          </p>

          <p>
            We solitary save the information that is provided by you or anybody
            browsing the Flynowithus. The information we save cannot be personal
            like travel date, destination airport to search for a flight, and
            personal information like your name, and email ID for flight ticket
            booking. This is solely under your power whether you provide this
            information or not.
          </p>

          <p>
            Apart from the information you specify to us willingly, we may
            collect some non-personal information directly from you while you
            browsing our website. And also collect some information indirectly
            from third-party applications and social networking sites like
            Facebook, Twitter and Instagram, etc.
          </p>
          <p>
            We may share some of your information with selected third-party
            websites like review site Trustpilot, who execute certain services
            on our behalf as per the instruction given by us. We assure that the
            third-party processors, who work on our behalf, abide by the data
            protection law and keep all your information secure under all
            circumstances. If the data collected from our customers are
            personal, they are administered under applicable protection law.
          </p>

          <p>
            If you are booking tickets or buying any services on our website, we
            share your information including personal details with service
            providers like airlines to process standard verification and fraud
            protection and in some cases with third parties who are authorized
            to provide services on behalf of travel providers.
          </p>

          <p>
            We may share some non-personal and completely anonymized information
            like aggregated Flynowithus usage of information or trend-level
            travel insights with a third party. Third-party advertising
            businesses may gather non-personal information from customers via
            different cookies and tracking tools to provide personalized
            services.
          </p>

          <p>
            To implement our terms of service, we may reveal your information
            whenever required. We have also the full right to reveal your
            information to block, identify, or prosecute any illegal or alleged
            illegal activity. We also may disclose your information to avoid any
            damages, administer our rights and claims, and retort to any legal
            action against us.
          </p>

          <p>
            Your personal information is accessible only by the Flynowithus
            employees, who need this information to contact you whenever
            required and provide you services at the Flynowithus website.
          </p>

          <p>
            While we put our best effort into safeguarding your personal
            information we are unable to assure you that the transmission of
            information through the Internet is completely secure. Therefore we
            cannot give you a 100% guarantee for the security of information
            that you share with us with full consent and risk.
          </p>

          <p>
            You can create an account on Flynowithus and manage the use of your
            personal information and subscription preferences also have the
            right to instruct us not to use your personal information for any
            marketing purpose at any time and you can contact us at the below
            details to exercise the right. If you want to opt out of receiving
            any promotional or marketing email from us, you can do the same by
            clicking the unsubscribe link at the bottom of any of our
            promotional emails. If you have any further issues or queries,
            contact us at the below details.
          </p>

          {/* </center> */}
        </div>
      </div>
      <br></br>

      <br></br>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
