import React from "react";
import HeaderWebsite from "./headerWebsite";
import Footer from "./components/Footer";

const Newsletter = () => {
  return (
    <>
      <HeaderWebsite />
      <div className="container">
        <div className="privacy-card">
          <center>
            <h3>Newsletter Terms And Conditions</h3>
          </center>
        </div>
        <div id="content-privacy" className="privacy-card">
          {/* <center> */}
          <p>
            By Subscribing To Our Newsletter, You Agree To Receive Emails From
            Flynowithus Best Flight Deals, Discounts And Travel Tips And More
            Via Email.
          </p>

          {/* </center> */}
        </div>
      </div>
      <br></br>

      <br></br>
      <Footer />
    </>
  );
};

export default Newsletter;
