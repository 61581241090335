import React from "react";
import HeaderWebsite from "./headerWebsite";
import Footer from "./components/Footer";

const About = () => {
  return (
    <>
      <HeaderWebsite />
      <div className="container">
        <div className="privacy-card">
          <center>
            <h3>About Us</h3>
          </center>
        </div>
        <div id="content-privacy" className="privacy-card">
          {/* <center> */}
          <p>Travel planning at your fingertips</p>
          <br></br>
          <br></br>
          <p>Keeping it simple</p>
          <p>
            We come together each day to fulfill a promise of offering the
            single most comprehensive travel experience to users, through
            award-winning Mobile and Desktop solutions. With intuitive products
            that have the largest selection of flights and hotels, we keep
            customers at the center of everything we do.
          </p>

          {/* </center> */}
        </div>
      </div>
      <br></br>

      <br></br>
      <Footer />
    </>
  );
};

export default About;
