import { Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import HeaderWebsite from "./headerWebsite";
import RoundTrip from "./roundtrip";
import Footer from "./components/Footer";
import { APIKEY } from "./APIKEY";
// import { useNavigate } from "react-router-dom";

const TravelApp = () => {
  const [tripType, setTripType] = useState("Round-trip");
  const [travelClass, setTravelClass] = useState("Economy");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [travellers, setTravellers] = useState(1);
  const getNextMonthDate = (day) => {
    const today = new Date();
    const nextMonth = today.getMonth() + 1; // Next month
    const nextMonthDate = new Date(today.getFullYear(), nextMonth, day);
    return nextMonthDate.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
  };
  const getNextMonthDateYMD = (day) => {
    const today = new Date();
    const nextMonth = today.getMonth() + 1; // Next month
    const nextMonthDate = new Date(today.getFullYear(), nextMonth, day);

    // Return in y-m-d format
    const year = nextMonthDate.getFullYear();
    const month = String(nextMonthDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const date = String(nextMonthDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${date}`;
  };
  var [deals, setDeals] = useState([
    {
      image: "images/BOM-rx.jpg",
      cityname: "Toronto",
      depart: getNextMonthDate(18),
      departYMD: getNextMonthDateYMD(18),
      arrivYMD: getNextMonthDateYMD(26),
      arriv: getNextMonthDate(26),
      from: "JFK",
      arrival: "YTO",
      lowprice: "$759.84",
      highprice: "$1066",
    },
    {
      image: "images/GOI-rx.jpg",

      cityname: "San Francisco",
      depart: getNextMonthDate(18),
      arriv: getNextMonthDate(26),
      departYMD: getNextMonthDateYMD(18),
      arrivYMD: getNextMonthDateYMD(26),
      from: "JFK",
      arrival: "SFO",
      lowprice: "$759.84",
      highprice: "$1066",
    },
    {
      image: "images/KTM-rx.jpg",

      cityname: "Vancouver",
      depart: getNextMonthDate(18),
      departYMD: getNextMonthDateYMD(18),
      arrivYMD: getNextMonthDateYMD(26),
      arriv: getNextMonthDate(26),
      from: "JFK",
      arrival: "YVR",
      lowprice: "$759.84",
      highprice: "$1066",
    },
    {
      image: "images/SGN-rx.jpg",

      cityname: "Ho Chi Minh City, Vietnam",
      depart: getNextMonthDate(18),
      departYMD: getNextMonthDateYMD(18),
      arrivYMD: getNextMonthDateYMD(26),
      arriv: getNextMonthDate(26),
      from: "JFK",
      arrival: "SGN",
      lowprice: "$759.84",
      highprice: "$1066",
    },
    {
      image: "images/SIN-rx.jpg",

      cityname: "Singapore, Singapore",
      depart: getNextMonthDate(18),
      departYMD: getNextMonthDateYMD(18),
      arrivYMD: getNextMonthDateYMD(26),
      arriv: getNextMonthDate(26),
      from: "JFK",
      arrival: "SIN",
      lowprice: "$759.84",
      highprice: "$1066",
    },
    {
      image: "images/BKK-rx.jpg",

      cityname: "Bangkok, Thailand",
      depart: getNextMonthDate(18),
      departYMD: getNextMonthDateYMD(18),
      arrivYMD: getNextMonthDateYMD(26),
      arriv: getNextMonthDate(26),
      from: "JFK",
      arrival: "BKK",
      lowprice: "$759.84",
      highprice: "$1066",
    },
  ]);

  // const handleFlights = (deal) => {
  //   //  e.preventDefault();
  //   var depar = deal.from;
  //   var arriv = deal.arrival;
  //   var depDate = deal.departYMD;
  //   var arivDate = deal.arrivYMD;
  //   var travellers = 1;
  //   var adults = 1;
  //   var children = 0;
  //   var infants = 0;
  //   var isReturnDatePresent = true;
  //   var travelClass2 = "Economy";

  //   // var ab = new AbortController();
  //   // ab.abort();

  //   // navigate("/flight-search", {
  //   //   state: {
  //   //     depar,
  //   //     arriv,
  //   //     depDate,
  //   //     arivDate,
  //   //     travellers,
  //   //     adults,
  //   //     children,
  //   //     infants,
  //   //     isReturnDatePresent,
  //   //     travelClass2,
  //   //   },
  //   // });
  // };
  const showForm = (type) => {
    setTripType(type);
  };
  const handleTravelClassChange = (e) => {
    setTravelClass(e.target.value);
  };

  useEffect(() => {
    const fetchFlights = async (dep, arr, depYMD, arrivYMD) => {
      var apiUrl = `https://api.flightapi.io/roundtrip/${APIKEY}/${dep}/${arr}/${depYMD}/${arrivYMD}/${1}/${0}/${0}/Economy/USD`;

      const response = await fetch(apiUrl);
      return response.json();
    };

    const updatePrices = async () => {
      const updatedDeals = await Promise.all(
        deals.map(async (deal) => {
          const data = await fetchFlights(
            deal.from,
            deal.arrival,
            deal.departYMD,
            deal.arrivYMD
          );

          if (data?.itineraries?.length > 0) {
            let cheapestPrice = Infinity;
            let highestPrice = -Infinity;

            data.itineraries.forEach((itinerary) => {
              const priceAmount = itinerary.cheapest_price.amount;

              if (priceAmount < cheapestPrice) {
                cheapestPrice = priceAmount;
              }

              if (priceAmount > highestPrice) {
                highestPrice = priceAmount;
              }
            });

            return {
              ...deal,
              lowprice: `$${cheapestPrice.toFixed(2)}`,
              highprice: `$${highestPrice.toFixed(2)}`,
            };
          }

          return deal; // If no itineraries found, return the original deal
        })
      );

      console.log(updatedDeals);

      setDeals(updatedDeals); // Update state with new prices
    };

    updatePrices();
    // console.log(deals);

    // let cheapestPrice = 0;
    // let highestPrice = 0;

    // Array(6)
    //   .fill()
    //   .map(async (_, index) => {
    //     var data = await fetchFlights(deals[index].from, deals[index].arrival);
    //     console.log(data);
    //     if (
    //       deals[index].arrival === "BOM" ||
    //       deals[index].arrival === "GOI" ||
    //       deals[index].arrival === "KTM" ||
    //       deals[index].arrival === "SGN" ||
    //       deals[index].arrival === "SIN" ||
    //       deals[index].arrival === "BKK"
    //     ) {
    //       if (data?.itineraries?.length > 0) {
    //         data.itineraries.forEach(async (itinerary) => {
    //           const priceAmount = itinerary.cheapest_price.amount;
    //           console.log("hyyy");
    //           cheapestPrice = priceAmount;
    //           // Update cheapest and highest prices
    //           if (priceAmount < cheapestPrice) {
    //             await (cheapestPrice = priceAmount);
    //           }
    //           if (priceAmount > highestPrice) {
    //             await (highestPrice = priceAmount);
    //           }
    //         });
    //         deals[index].lowprice = cheapestPrice;
    //         deals[index].highprice = highestPrice;
    //         console.log(deals);
    //       }
    //     }
    //   });
    // return;
  }, []);

  // const navigate = useNavigate();

  return (
    <>
      <HeaderWebsite />
      <main>
        <div className="title-text"></div>
        <div className="main-container">
          <div className="text2">
            Compare flights across hundreds of providers!
          </div>
          <div className="bookings">
            <ul className="panels">
              <li className="flight-tab panel">
                <a href="#">Flight</a>
              </li>
            </ul>
          </div>

          <div className="flight-panel" id="Flight-panel">
            <div className="trip-type">
              <form className="trip-type-1">
                {/* {["Round-trip", "One Way", "Multi-City"].map((type, index) => ( */}
                {["Round-trip", "One Way"].map((type, index) => (
                  <div key={index}>
                    <input
                      style={{ marginRight: "4px" }}
                      type="radio"
                      id={`option${index + 1}`}
                      name="choice"
                      value={type}
                      defaultChecked={tripType === type}
                      onClick={() => showForm(type)}
                    />
                    <label htmlFor={`option${index + 1}`}>{type}</label>
                  </div>
                ))}

                <Form.Select
                  aria-label="Default select example"
                  style={{
                    marginTop: "-3px",
                    outline: "none",
                    width: "auto",
                    color: "blue",
                  }}
                  className="Customselect"
                  value={travelClass}
                  onChange={handleTravelClassChange}
                >
                  <option className="select-option" value="Economy">
                    Economy
                  </option>
                  <option className="select-option" value="Business">
                    Business
                  </option>
                  <option className="select-option" value="First">
                    First
                  </option>
                  <option className="select-option" value="Premium_Economy">
                    Premium Economy
                  </option>
                </Form.Select>
              </form>
            </div>
            {tripType === "Round-trip" && (
              <RoundTrip travelClass={travelClass} />
            )}
            {tripType === "One Way" && (
              <RoundTrip
                isReturnDatePresent={false}
                travelClass={travelClass}
              />
            )}
            {tripType === "Multi-City" && (
              <RoundTrip travelClass={travelClass} />
            )}
          </div>

          <div className="helpline">
            <div className="helpline-text">
              <span className="call-text">
                <span style={{ fontSize: "inherit", fontWeight: "bold" }}>
                  Call us 24/7 at
                </span>

                <a href="tel:+1(855) 478-6093" className="pl-1">
                  <span style={{ color: "green" }}> +1(855) 478-6093</span>
                </a>
              </span>
              <br />
              <span className="desp-text">
                Need help booking? Our agents are ready!
              </span>
              <br />
              <span>Choose from over 500 airlines.</span>
            </div>
          </div>
          <div className="explore">
            Found these <span className="low-color">low</span> fare deals for
            your next trip
          </div>
          <div className="trips-container">
            <div className="trips">
              {deals.map((deal, index) => (
                <div className="trip-card cursor-pointer" key={index}>
                  <img
                    src={deal.image}
                    alt="New York City"
                    className="trip-image"
                  />
                  <div className="trip-content">
                    <h3 className="city">{deal.cityname}</h3>
                    <p className="date-range">
                      {deal.depart} - {deal.arriv}
                    </p>
                    <p className="route">
                      {deal.from} – {deal.arrival}
                    </p>
                    <div className="price-section">
                      <span className="price">{deal.lowprice}</span>
                      <span className="trip-type">Round Trip</span>
                    </div>
                  </div>
                  <div className="price-indicator">
                    <div className="price-bar">
                      <span className="low">{deal.lowprice}</span>
                      <div className="price-bar-line">
                        <span className="bar"></span>
                      </div>
                      <span className="high">{deal.highprice}</span>
                    </div>
                    <p className="trip-text">
                      Similar trip cost to {deal.cityname}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default TravelApp;
